import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query NewsletterQuery {
    craft {
      globalSet(handle: "newsletter") {
        ... on Craft_newsletter_GlobalSet {
          label: label0
          heading: heading0
          copy: descriptor0
          formCode
        }
      }
    }
  }
`;

const dataResolver = ({ globalSet }) => globalSet;

const useData = () => {
  const { craft: data } = useStaticQuery(query);
  return dataResolver(data);
};

export default useData;
