import React from "react";
import clsx from "clsx";
import { AppLink } from "@base";
import Icon from "./Icon";

const ButtonAlt = ({
  to,
  children,
  className: _className,
  reverse = false,
  icon = "rightArrow",
  onClick,
}) => {
  return (
    <AppLink
      to={to}
      onClick={onClick}
      className={clsx(
        "group inline-flex items-center gap-2 transition",
        _className
      )}
    >
      {/* back arrow */}
      {icon && reverse && (
        <Icon name={icon || "chevron"} className="flex-shink-0 ml-1 h-4 w-4" />
      )}
      {/* label */}
      <span className="font-pp-right font-black uppercase">{children}</span>
      {/* forward arrow */}
      {icon && !reverse && (
        <div className="flex h-8 w-8 items-center justify-center overflow-hidden rounded-full bg-yellow text-green transition duration-200 group-hover:translate-x-1">
          <Icon
            name={icon || "chevron"}
            className="flex-shink-0 ml-1 h-4 w-4 transition duration-200 group-hover:translate-x-0.5"
          />
        </div>
      )}
    </AppLink>
  );
};

export default ButtonAlt;
