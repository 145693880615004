import React from "react";
import { Text, Button, LinkAlt } from "@atoms";

const CbButton = ({ link, style }) => {
  return (
    <div className="my-10">
      {link?.url &&
        (style ? (
          <Button to={link?.url}>
            <Text variant="button">{link?.text}</Text>
          </Button>
        ) : (
          <LinkAlt to={link?.url} text={link?.text} />
        ))}
    </div>
  );
};

export default CbButton;
