import React from "react";
import clsx from "clsx";

const Container = ({
  children,
  className: _className,
  variant = "md",
  padding,
  interior,
}) => {
  let classes;
  const px = "px-4 sm:px-6 md:px-8 lg:px-10 xl:px-16";
  // if (flush) {
  //   margin = "-mx-3 sm:-mx-6 md:-mx-8 lg:-mx-12";
  // }
  switch (variant) {
    case "xxs":
      classes = "max-w-2xl xl:max-w-3xl mx-auto";
      break;
    case "xs":
      classes = "max-w-3xl xl:max-w-4xl mx-auto";
      break;
    case "sm":
      classes = "max-w-4xl xl:max-w-5xl mx-auto";
      break;
    case "lg":
      classes = "max-w-6xl xl:max-w-7xl mx-auto";
      break;
    case "xl":
      classes = "max-w-7xl xl:max-w-8xl mx-auto";
      break;
    default:
      classes = "max-w-5xl xl:max-w-6xl mx-auto";
      break;
  }
  if (padding && !interior) {
    return (
      <div
        className="px-0
       sm:px-4 lg:px-12 xl:px-8"
      >
        <div className={clsx("w-full", classes, _className, px)}>
          {children}
        </div>
      </div>
    );
  }
  if (padding && interior) {
    return (
      <div className="-px-3 sm:px-0">
        <div className={clsx("w-full", classes, _className, px)}>
          {children}
        </div>
      </div>
    );
  }
  return (
    <div className={clsx("w-full", classes, _className, px)}>{children}</div>
  );
};

export default Container;
