import React from "react";
import { Image, Icon } from "@atoms";
import { AppLink } from "@base";
import usePodcastsData from "@staticQueries/AllPodcastsQuery";

const PodcastFeaturedImage = ({ image }) => {
  const { podcastInfo, podcastAppleLink, podcastSpotifyLink } =
    usePodcastsData();
  return (
    <>
      <Image
        image={{
          url: image || podcastInfo?.image?.url,
          skipBuild: true,
          height: 1400,
          width: 1400,
        }}
      />
      <div className="mt-4 flex gap-3">
        {podcastAppleLink?.url && (
          <AppLink
            to={podcastAppleLink?.url}
            ariaLabel={podcastAppleLink?.text}
            className="transition hover:opacity-50"
          >
            <Icon name="applePodcasts" className="w-full" />
          </AppLink>
        )}
        {podcastSpotifyLink?.url && (
          <AppLink
            to={podcastSpotifyLink?.url}
            ariaLabel={podcastSpotifyLink?.text}
            className="transition hover:opacity-50"
          >
            <Icon name="spotifyPodcasts" className="w-full" />
          </AppLink>
        )}
      </div>
    </>
  );
};

export default PodcastFeaturedImage;
