import React from "react";
import { Text, Image } from "@atoms";
import { useAppState } from "@state";
import loadable from "@loadable/component";

const Video = loadable(() => import("../atoms/Video"));

const VideoCard = ({ title, excerpt, videoUrl: url }) => {
  let id;
  const provider = url.match("http(s)?://(www.)?youtube|youtu.be")
    ? "youtube"
    : "vimeo";
  if (provider === "youtube") {
    if (url.match("embed")) {
      [id] = url.split(/embed\//)[1].split('"');
    } else {
      [id] = url.split(/v\/|v=|youtu\.be\//)[1].split(/[?&]/);
    }
  }
  const image = {
    url: `https://i3.ytimg.com/vi/${id}/hqdefault.jpg`,
    skipBuild: true,
    height: 287,
    width: 556,
  };

  const [, dispatch] = useAppState();

  const triggerModal = () => {
    dispatch({
      type: "openModal",
      // eslint-disable-next-line react/no-unstable-nested-components
      content: React.memo(function Modal() {
        return (
          <div className="pointer-events-auto w-full">
            <Video url={url} showControls autoplay={false} loop={false} />
          </div>
        );
      }),
    });
  };

  return (
    <button
      className="text-left text-white"
      type="button"
      onClick={triggerModal}
      aria-label={`Open the ${title} video`}
    >
      <Image image={image} />
      <div className="mt-4">
        <Text variant="h6">{title}</Text>
        <Text className="mt-2 line-clamp-2">{excerpt}</Text>
      </div>
    </button>
  );
};

export default VideoCard;
