import React from "react";
import { AppLink } from "@base";
import clsx from "clsx";

const Button = ({
  color = "yellow",
  to,
  title,
  type = "button",
  onClick,
  linkObj: LinkObj = AppLink,
  children,
  fullWidth,
  size = "sm",
  rounded,
  faux,
}) => {
  const defaultClassName = `inline-flex justify-center items-center cursor-pointer items-center relative z-0 font-bold group leading-tighter transition duration-100`;

  const sizes = {
    lg: "px-10 py-5 text-base",
    md: "p-4 text-xs",
    sm: "py-2.5 px-4 text-xs",
    xs: "py-1 px-4 text-xxs",
  };

  const colors = {
    black:
      "bg-black duration-300 hover:bg-yellow text-white hover:text-black focus:text-black focus:bg-yellow transition",
    red: "bg-red hover:bg-red-dark text-black",
    yellow:
      "transition-colors duration-300 border-4 bg-yellow border-transparent hover:border-green-dark text-black hover:bg-black group-hover:text-white group-hover:bg-black hover:text-white",
    yellowForm:
      "transition-colors duration-300 border-4 bg-yellow border-transparent hover:border-white text-black hover:bg-transparent group-hover:text-white group-hover:bg-transparent hover:text-white",
    transparent:
      "transition-colors duration-300 bg-transparent text-black border-4 border-black hover:border-yellow hover:bg-yellow group-hover:border-yellow group-hover:bg-yellow hover:text-green-dark",
    transparentWhite:
      "transition-colors duration-300 bg-transparent text-white border-4 border-white hover:border-yellow hover:bg-yellow group-hover:border-yellow group-hover:bg-yellow hover:text-green-dark",
    transparentCard:
      "transition-colors duration-300 bg-transparent text-black border-4 border-black group-hover:text-white group-hover:bg-black",
  };

  const classes = clsx(
    defaultClassName,
    sizes[size] || "py-4 px-10 text-sm",
    colors[color] || color,
    {
      "rounded-full": rounded,
      "w-full": fullWidth,
    }
  );

  if (to && to.length > 1) {
    return (
      <LinkObj to={to} title={title} className={classes} onClick={onClick}>
        <span className="relative z-10 flex text-center">{children}</span>
      </LinkObj>
    );
  }
  if (faux) {
    return (
      <span className={classes}>
        <span className="relative z-10 flex text-center">{children}</span>
      </span>
    );
  }
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={classes}
      onClick={onClick}
    >
      <span className="relative z-10 flex text-center">{children}</span>
    </button>
  );
};

export default Button;
