import React from "react";
import Form from "./Form";

const JobForm = ({ formCode }) => {
  return (
    <Form
      heading="Please Fill Out This Form To Apply"
      id={formCode}
      dependencies={[]}
      isGiving={false}
      dark
    />
  );
};

export default JobForm;
