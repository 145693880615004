import React from "react";

import MapMarker from "@svg/mapMarker.svg";

const Marker = ({ onClick, title }) => (
  <button
    type="button"
    className="flex -translate-y-1/2 flex-col items-center justify-center gap-1 text-center"
    onClick={onClick}
  >
    <span className="max-w-[10rem] font-pp-right  font-bold uppercase leading-none text-white">
      {title}
    </span>
    <MapMarker name="mapMarker" className="h-6 w-6 text-yellow" />
  </button>
);

export default Marker;
