import React from "react";
import { Image, Text, Button } from "@atoms";
import { useAppState } from "@state";
import ModalContent from "./ModalContent";

const TimelineNode = ({ heading, description, image, modalContent }) => {
  const [, dispatch] = useAppState();

  const triggerModal = () => {
    dispatch({
      type: "openModal",
      title: heading,
      description: `${heading} timeline description`,
      // eslint-disable-next-line react/no-unstable-nested-components
      content: React.memo(function Modal() {
        return (
          <ModalContent
            heading={heading}
            content={modalContent}
            image={image}
          />
        );
      }),
      hideButton: true,
    });
  };
  return (
    <li className="mb-20">
      <button
        type="button"
        className="group relative inline-block grid grid-cols-1 gap-6 pl-4 text-left sm:grid-cols-2 sm:gap-10 sm:pl-0"
        onClick={triggerModal}
      >
        <div className="durration-300 absolute inset-0 top-16 z-10 h-6 w-6 scale-75 overflow-hidden rounded-full bg-green-dark transition group-hover:scale-100 sm:left-1/2 sm:-ml-2.5 lg:ml-[2.37rem]" />
        <div className="durration-300 absolute inset-0 top-16 z-0 h-6 w-6 scale-75 overflow-hidden rounded-full border-3 border-blue bg-offwhite transition group-hover:scale-[1.85] sm:left-1/2 sm:-ml-2.5 lg:ml-[2.37rem]" />
        <div className="space-y-8 place-self-center px-4 sm:px-8">
          <Text variant="h6">{heading}</Text>
          <Text variant="sm">{description}</Text>
          {modalContent && (
            <Button color="transparent" faux>
              <Text variant="button" className="uppercase">
                Learn more
              </Text>
            </Button>
          )}
        </div>
        <div className="w-full max-w-2xs px-4 sm:justify-self-end">
          <Image
            image={image}
            className="aspect-square w-full overflow-hidden rounded-full"
          />
        </div>
      </button>
    </li>
  );
};

export default TimelineNode;
