import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query AllVideosQuery {
    craft {
      videos: entries(section: "video") {
        ... on Craft_video_default_Entry {
          uid
          title
          excerpt: descriptor0
          videoUrl
          videoCategories {
            ... on Craft_videoCategories_Category {
              uid
              title
            }
          }
        }
      }
      categories(group: "videoCategories") {
        ... on Craft_videoCategories_Category {
          uid
          title
        }
      }
    }
  }
`;

const dataResolver = ({ videos, categories }) => {
  return {
    videos,
    categories,
  };
};

const useDefaultsData = () => {
  const { craft: data } = useStaticQuery(query);
  return dataResolver(data);
};

export default useDefaultsData;
