import React, { useState, useRef, useEffect } from "react";
import { Image, Icon, Text, ButtonAlt } from "@atoms";
import { AppLink } from "@base";
import clsx from "clsx";
import { useScroll } from "framer-motion";
import toggleBodyScroll from "@utils/toggleBodyScroll";

const SideBar = ({
  image,
  title,
  close,
  excerpt,
  wildlife,
  activities,
  directionUrl,
  url,
}) => {
  const outerProgress = useRef(null);
  const [headerTrigger, setHeaderTrigger] = useState(false);
  const { scrollYProgress } = useScroll({
    container: outerProgress,
  });

  useEffect(() => {
    const unsubscribe = scrollYProgress.onChange(v => {
      setHeaderTrigger(f => {
        if (v > 0.25 && !f) {
          return true;
        }
        if (f && v <= 0.25) {
          return false;
        }
        return f;
      });
    });

    if (outerProgress.current) toggleBodyScroll(false, outerProgress.current);
    return () => {
      unsubscribe();
      if (outerProgress.current) toggleBodyScroll(true, outerProgress.current);
    };
  }, []);

  return (
    <div
      className="relative flex h-full w-full flex-col overflow-y-auto bg-green-dark text-white"
      ref={outerProgress}
    >
      {/* image + title */}
      <div
        className={clsx(
          "absolute top-0 z-10 w-full select-none px-10 transition-[padding] transition-colors duration-300",
          {
            "bg-green-dark py-12": headerTrigger,
            "bg-black py-14 sm:py-20": !headerTrigger,
          }
        )}
      >
        {image && (
          <Image
            className={clsx("mix-blend-lighten", {
              "opacity-50": !headerTrigger,
              "opacity-0": headerTrigger,
            })}
            image={image}
            fill
            draggable={false}
          />
        )}
        <div className="absolute left-5 top-5 z-20">
          <button
            type="button"
            title="close"
            aria-label="close"
            onClick={close}
            className="pointer-events-auto"
          >
            <Icon name="close" className="h-6 w-6" />
          </button>
        </div>
        <div className="relative z-20 flex items-center gap-3">
          <Icon name="holding" className="w-16 shrink-0" />
          <Text variant="h6">{title}</Text>
        </div>
      </div>
      {/* copy */}
      <div className="mt-40 flex-grow divide-y-2 divide-white p-10 sm:mt-60">
        {excerpt && (
          <div className="mb-10 leading-tight">
            <Text variant="body">{excerpt}</Text>
          </div>
        )}
        {!!activities?.length && (
          <div className="mb-10 pt-10">
            <h4 className="mb-5 font-bold uppercase">Available Activities</h4>
            <ul className="flex flex-col gap-5">
              {activities?.map(activity => (
                <li key={activity}>{activity}</li>
              ))}
            </ul>
          </div>
        )}
        {!!wildlife?.length && (
          <div className="mb-10 pt-10">
            <h4 className="mb-5 font-bold uppercase">Wildlife</h4>
            <ul className="flex flex-col gap-5">
              {wildlife?.map(animal => (
                <li key={animal}>{animal}</li>
              ))}
            </ul>
          </div>
        )}
        {directionUrl && (
          <div className="mb-10 pt-10 text-center">
            <AppLink
              to={directionUrl}
              className="group flex flex-col items-center justify-center gap-8"
            >
              <Icon
                name="sideBarMarker"
                className="h-20 w-20 text-white transition group-hover:-translate-y-2 md:h-24 md:w-24"
              />
              <Text variant="h6">Get Directions</Text>
            </AppLink>
          </div>
        )}
        {url && (
          <div className="mb-10 pt-10">
            <Text
              variant="xs"
              className="mb-6"
            >{`If you’d like to learn more about ${title}, visit the page for information.`}</Text>
            <ButtonAlt to={url}>
              <Text variant="button">Visit Page</Text>
            </ButtonAlt>
          </div>
        )}
      </div>
    </div>
  );
};

export default SideBar;
