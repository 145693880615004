import React from "react";
import clsx from "clsx";
import { AppLink } from "@base";
import { Text, Button } from "@atoms";

const Card = ({
  icon,
  heading,
  description,
  linkObject,
  index,
  classNames: _className,
  jumbleColor,
}) => {
  const { svgCode } = icon || {};
  const colors = [
    "bg-blue",
    "bg-yellow",
    "bg-salmon",
    "bg-salmon",
    "bg-blue",
    "bg-yellow",
  ];
  const Tag = linkObject?.url ? AppLink : "div";
  return (
    <div
      className={clsx("relative flex w-full max-w-2xs", _className, {
        group: linkObject?.url,
      })}
    >
      <div
        className={clsx(
          "absolute z-0 h-full w-full",
          colors[index % (!jumbleColor ? 3 : 6)],
          {
            "transition-transform group-hover:scale-105": linkObject?.url,
          }
        )}
      />
      <Tag
        to={linkObject?.url}
        className="relative z-10 flex w-full flex-col items-center justify-between px-6 py-14 text-center"
      >
        <div className="flex h-full flex-col items-center justify-start gap-4">
          {svgCode && (
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: svgCode }}
              className="mx-auto mb-6 h-full w-auto transition group-hover:-translate-y-3"
            />
          )}
          <div className="flex h-full flex-col justify-between gap-4">
            <Text variant="h6" className="mx-auto text-center text-green-dark">
              {heading}
            </Text>
            {description && (
              <Text
                variant="xs"
                richText
                className="mx-center max-w-2xs text-green-dark"
              >
                {description}
              </Text>
            )}
            {linkObject?.url && (
              <div>
                <Button faux color="transparentCard" className="inline">
                  <Text variant="button" className="uppercase">
                    {linkObject?.text}
                  </Text>
                </Button>
              </div>
            )}
          </div>
        </div>
      </Tag>
    </div>
  );
};

export default Card;
