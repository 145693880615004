import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";
import { m, useAnimation } from "framer-motion";
import Icon from "./Icon";

// todo: add documentation

const Dropdown = ({
  model,
  options,
  placeholder = "Select One",
  inverse,
  size = "md",
  sans,
  id = "dropdown",
  label,
}) => {
  const [isActive, setIsActive] = useState(false);
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [value, setValue] = model || useState();
  const dropdown = useRef();
  const dropdownControl = useAnimation();

  // close menu if it's open and you click outside of it
  const handleClickOutside = event => {
    if (dropdown.current && !dropdown.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  // change state and close menu when a dropdown item is selected
  useEffect(() => {
    setIsActive(false);
  }, [value]);

  // animations
  useEffect(() => {
    if (isActive) {
      dropdownControl.start({
        height: "auto",
        opacity: 1,
        display: "block",
        transition: {
          type: "tween",
          duration: 0.25,
          ease: "easeIn",
        },
      });
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("touchstart", handleClickOutside);
    }
    if (!isActive) {
      dropdownControl.start({
        height: 0,
        opacity: 0,
        transition: {
          type: "tween",
          duration: 0.25,
          ease: "easeIn",
        },
        transitionEnd: {
          display: "hidden",
        },
      });
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    }
  }, [isActive]);

  // eslint-disable-next-line global-require

  let textSize;
  let iconSize;

  switch (size) {
    case "xs":
      textSize = "text-xs";
      iconSize = "w-1 h-1";
      break;
    case "sm":
      textSize = "text-sm";
      iconSize = "w-1 h-1";
      break;
    case "md":
      textSize = "text-md";
      iconSize = "w-3 h-3";
      break;
    case "lg":
      textSize = "text-lg";
      iconSize = "w-4 h-4";
      break;
    default:
      textSize = "text-md";
      iconSize = "w-3 h-3";
      break;
  }

  return (
    <div
      className={clsx("relative w-full", {
        "font-sans text-xs font-bold uppercase": sans,
        "font-serif": !sans,
      })}
    >
      <m.button
        type="button"
        ref={dropdown}
        onTap={toggleActive}
        className={clsx(
          "duration-400 flex w-full items-center justify-between border transition",
          {
            "px-3 py-1": size === "xs",
            "px-3 py-2": size !== "xs",
            "bg-black text-white": inverse,
            "text-black": !inverse,
            "border-b border-b-black bg-white": !inverse && isActive,
          },
          textSize
        )}
        aria-expanded={isActive}
        aria-controls={id}
        aria-haspopup="listbox"
      >
        <span className="mr-2 whitespace-nowrap text-left font-bold">
          {value != null && value !== ""
            ? options?.[value]?.title
            : placeholder}
        </span>
        {/* dropdown Icon */}
        <Icon
          name="chevron"
          className={clsx(
            "icon transform text-green duration-100",
            {
              "-rotate-90": isActive,
              "rotate-90": !isActive,
            },
            iconSize
          )}
        />
      </m.button>
      <div className="relative w-full">
        <m.ul
          animate={dropdownControl}
          className={clsx(
            "absolute left-0 right-0 top-2 z-40 h-0 w-max divide-y divide-black overflow-hidden border-b border-l border-r",
            {
              "border-green bg-black text-white": inverse,
              "border-gray-light bg-white text-black": !inverse,
              "shadow-xl": isActive,
              "shadow-none": !isActive,
            },
            textSize
          )}
          id={id}
          role="listbox"
          aria-label={label || placeholder || `dropdown--${id}`}
          tabIndex="-1"
        >
          {placeholder.length > 1 && (
            <m.li
              role="option"
              aria-selected={false}
              className={clsx(
                "w-full cursor-pointer px-4 py-2 text-left font-bold opacity-50",
                textSize
              )}
              disabled
            >
              {placeholder}
            </m.li>
          )}
          {options?.map((option, i) => {
            return (
              <m.li
                role="option"
                aria-selected={value === option.uid}
                key={option.uid || option.value}
                onTapStart={() => setValue(i)}
                className="w-full cursor-pointer px-4 py-2 text-left font-bold transition duration-100 hover:bg-yellow focus:bg-green"
                tabIndex={isActive ? "0" : "-1"}
              >
                {option.title}
              </m.li>
            );
          })}
        </m.ul>
      </div>
    </div>
  );
};

export default Dropdown;
