import React from "react";
import { Icon } from "@atoms";

const ComponentName = () => {
  return (
    <div className="flex items-center text-sm font-bold ">
      <Icon name="logo" className="w-40 sm:w-52" />
    </div>
  );
};

export default ComponentName;
