import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Text } from "@atoms";
import { Script } from "gatsby";

const Form = React.memo(
  function Form({
    heading,
    description,
    id,
    isGiving,
    dependencies,
    dark,
    small,
    formPage,
  }) {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
      // add script if none exists
      if (!document.getElementById("vscript")) {
        // eslint-disable-next-line no-console
        console.log("Loading ActionTag...");
        const newVscript = document.createElement("script");
        newVscript.setAttribute("id", "vscript");
        newVscript.setAttribute(
          "src",
          "https://cdn.virtuoussoftware.com/virtuous.embed.min.js"
        );
        document.body.appendChild(newVscript);
      }
      //   // load new scripts
      setTimeout(() => {
        setLoaded(true);
      });
      return () => {
        // remove vs script
        if (document.getElementById("vscript")) {
          // eslint-disable-next-line no-console
          console.log("Resetting VirtuousForms...");
          document.getElementById("vscript").remove();
        }
        // clear scripts
        if (window?.VirtuousForms?.Configuration) {
          Object.values(window.VirtuousForms.Configuration).forEach(v => {
            const el = document.querySelector(`script[src='${v}']`);
            if (el) {
              el.remove();
            }
          });
        }
        // clear virtuos window vars
        [
          "VirtuousForms",
          "virtuousForm",
          "virtuousForms",
          "virtuousPayTokenizer",
        ].forEach(script => {
          if (window[script]) {
            window[script] = {};
          }
        });
      };
    }, []);

    useEffect(() => {
      // if the form is successfully inputted,
      //  scroll it into view
      if (loaded && formPage) {
        setTimeout(() => {
          if (window.VirtuousForms) {
            // eslint-disable-next-line no-console
            console.log("Setting up up onSuccess event...");
            window.VirtuousForms.settings = {
              onSuccess: () => {
                setTimeout(
                  document
                    .getElementById(`virtuous--${id}`)
                    .scrollIntoView({ behavior: "smooth" }),
                  150
                );
              },
            };
            // eslint-disable-next-line no-console
            console.log(
              window.VirtuousForms.settings.onSuccess
                ? "onSuccess event added"
                : "onSuccess failed to be added"
            );
          }
        }, 200);
      }
    }, [loaded]);

    return (
      <div
        id={`virtuous--${id}`}
        className={clsx(
          "virtuous-form croll-my-4 w-full border-b-[19px] border-salmon p-8",
          {
            "virtuous-form--light bg-white text-black": !dark,
            "virtuous-form--dark bg-green-dark text-white": dark,
            "virtuous-form--full": !small,
            "virtuous-form--small": small,
          }
        )}
      >
        {heading && (
          <Text
            variant={!small ? "h5" : "h3"}
            className="px-8 text-center text-inherit"
          >
            {heading}
          </Text>
        )}
        {description && (
          <Text
            variant={!small ? "body" : "sm"}
            className="mx-auto my-4 max-w-lg text-center"
          >
            {description}
          </Text>
        )}
        {loaded && (
          <Script
            id={`fattpay-${id}`}
            src="https://fattjs.fattpay.com/js/fattmerchant.js"
          />
        )}
        <script
          data-vform={id}
          data-orgId="430"
          data-isGiving={isGiving}
          data-merchantType={isGiving ? "Virtuous" : null}
          data-dependencies={`[${dependencies}]`}
        />
      </div>
    );
  },
  () => true
);

export default Form;
