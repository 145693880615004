import React from "react";
import { Text, Icon, Button, LinkAlt } from "@atoms";
import clsx from "clsx";
import { m } from "framer-motion";

const AccordionItem = ({
  header,
  copy,
  button,
  secondaryLink,
  i,
  state: _state,
}) => {
  const [open, setOpen] = _state;
  return (
    <div
      className={clsx("my-2 w-full border-b-5", {
        "border-transparent": open !== i,
        "border-black": open === i,
      })}
    >
      <button
        type="button"
        aria-label={`Expand accordion containing information on ${header}`}
        className="flex w-full justify-between border-l-8 border-transparent p-4 py-6 text-left hover:border-black hover:bg-yellow focus:border-black focus:bg-yellow"
        onClick={() => setOpen(last => (last === i ? -1 : i))}
      >
        <Text variant="h6">{header}</Text>
        <Icon
          name="chevron"
          className={clsx(
            "ml-2 h-2.5 w-2.5 shrink-0 text-green-dark transition",
            {
              "-rotate-90": open === i,
              "rotate-90": open !== i,
            }
          )}
        />
      </button>
      <m.div
        className="overflow-hidden"
        initial={{ height: 0 }}
        animate={{ height: open === i ? "auto" : 0 }}
        transition={{
          height: { ease: [0.83, 0, 0.17, 1], duration: 0.2 },
        }}
      >
        <div className="px-5 pb-6 pt-1">
          <Text variant="sm" richText>
            {copy}
          </Text>

          <div className="mt-4 flex items-center gap-4">
            {button?.url && (
              <Button
                to={button?.url}
                className="inline"
                size="xs"
                color="transparent"
              >
                <Text variant="sm">{button?.text}</Text>
              </Button>
            )}
            {secondaryLink?.url && (
              <LinkAlt to={secondaryLink?.url} text={secondaryLink?.text} />
            )}
          </div>
        </div>
      </m.div>
    </div>
  );
};

export default AccordionItem;
