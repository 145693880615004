import React from "react";
import clsx from "clsx";
import { Wrapper, AppLink } from "@base";
import Text from "./Text";

const LinkAlt = ({
  to,
  text,
  dark,
  light,
  classNames: _className,
  onClick,
}) => {
  return (
    <Wrapper
      condition={to}
      component={AppLink}
      tag="span"
      to={to}
      onClick={onClick}
      className={clsx(
        "underline underline-offset-4 transition-opacity duration-100 hover:opacity-60 group-hover:opacity-60",
        _className,
        {
          "underline-green-dark": dark,
          "underline-white text-white": light,
        }
      )}
    >
      <Text variant="xs">{text}</Text>
    </Wrapper>
  );
};

export default LinkAlt;
