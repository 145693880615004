import React, { useState, useEffect, useRef } from "react";
import { Text, Icon, Button } from "@atoms";
import { AppLink } from "@base";
import { useAppState } from "@state";
import clsx from "clsx";
import { m, AnimatePresence } from "framer-motion";
import toggleBodyScroll from "@utils/toggleBodyScroll";

const HeaderDropdown = ({ text, url, children, uid }) => {
  const [visible, setVisible] = useState(false);
  const variants = {
    open: { height: "auto" },
    closed: { height: 0 },
  };

  return (
    <li className="relative" key={uid}>
      <div
        className={clsx(
          "relative flex w-full items-center justify-between px-6 py-1.5",
          {
            "bg-yellow": visible,
          }
        )}
      >
        <AppLink
          className={clsx("relative z-20 py-2 text-base font-bold", {
            "inline-block": children?.length > 0,
            block: !children?.length,
          })}
          to={url}
        >
          <Text variant="navMobile" className="justify-end text-black">
            {text}
          </Text>
        </AppLink>
        {children?.length > 0 && (
          <button
            type="button"
            className="absolute left-0 top-0 z-10 flex h-full w-full justify-end px-6 text-black"
            onClick={() => setVisible(!visible)}
            aria-label={`Open ${text} dropdown`}
          >
            <Icon
              name="chevronSmall"
              className={clsx("mr-2 mt-4 h-2.5 w-2.5 transition", {
                "-rotate-90 text-green": visible,
                "rotate-90 text-yellow": !visible,
              })}
            />
          </button>
        )}
      </div>
      {children?.length > 0 && (
        <m.div
          className="relative z-10 overflow-hidden px-6"
          animate={visible ? "open" : "closed"}
          initial="closed"
          variants={variants}
          transition={{ duration: 0.3 }}
        >
          <ul className="my-2 space-y-3">
            {children?.map(subLink => {
              const { uid: _uid, text: _text, url: _url } = subLink;
              return (
                <li key={_uid} className="">
                  <AppLink
                    to={_url}
                    className="font-sans text-xs font-bold leading-normal sm:text-sm"
                  >
                    {_text}
                  </AppLink>
                </li>
              );
            })}
          </ul>
        </m.div>
      )}
    </li>
  );
};

const MobileHeader = ({ nav }) => {
  const [{ layout }] = useAppState();
  const navRef = useRef(null);
  // Mobile nav triggers
  const { hideNav } = layout;

  useEffect(() => {
    if (navRef.current) toggleBodyScroll(hideNav, navRef.current);
  }, [hideNav]);

  if (nav) {
    return (
      <nav
        aria-label="Mobile navigation"
        aria-live={!hideNav ? "polite" : "off"}
      >
        <AnimatePresence>
          {!hideNav && (
            <m.ul
              ref={navRef}
              className={clsx(
                "absolute left-0 right-0 top-0 z-50 h-screen w-full max-w-[100vw] overflow-auto bg-white pb-6 pt-16 sm:pt-[5rem] md:hidden",
                {
                  "pointer-events-none": hideNav,
                }
              )}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              {nav?.map(link => {
                const { text, url, type, uid } = link;

                if (type === "button") {
                  return (
                    <li key={uid} className="mt-3 px-6">
                      <Button to={url}>
                        <Text variant="navMobile">{text}</Text>
                      </Button>
                    </li>
                  );
                }

                return <HeaderDropdown key={uid || url} {...link} />;
              })}
            </m.ul>
          )}
        </AnimatePresence>
      </nav>
    );
  }
  return null;
};

export default MobileHeader;
