import React, { useState } from "react";
import clsx from "clsx";
import useVideoData from "@staticQueries/AllVideosQuery";
import { Text } from "@atoms";
import { m } from "framer-motion";

const VideoFilter = ({ filterState }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [category, setCategory] = filterState || useState(0);
  const { categories } = useVideoData();
  // mobile functionality
  const [open, setOpen] = useState(false);
  const variants = {
    show: {
      height: "auto",
      pointerEvents: "auto",
    },
    hide: {
      height: 0,
      pointerEvents: "none",
    },
  };

  return (
    <>
      <div className="z-20 hidden flex-nowrap divide-x-2 divide-black md:flex">
        {categories?.map((cat, i) => {
          return (
            <div key={cat.uid || cat.title} className="group relative shrink-0">
              <button
                type="button"
                title={`change category to ${cat.title}`}
                aria-label={`change category to ${cat.title}`}
                className="flex items-center justify-center bg-white p-6 transition"
                onClick={() => setCategory(lastCat => (lastCat !== i ? i : -1))}
              >
                <span
                  className={clsx(
                    "absolute text-center transition hover:font-bold group-hover:-translate-y-0.5 group-hover:font-bold group-focus:-translate-y-0.5 group-focus:font-bold",
                    {
                      "-translate-y-0.5 font-bold": category === i,
                    }
                  )}
                >
                  <Text variant="sm">{cat?.title}</Text>
                </span>
                <span className="invisible block opacity-0" aria-hidden="true">
                  {cat?.title}
                </span>
              </button>
              <div
                className={clsx(
                  "pointer-events-none absolute bottom-0 h-0 w-full bg-yellow transition-[height] group-hover:h-[12px] group-active:h-[12px]",
                  {
                    "h-[12px]": category === i,
                  }
                )}
              />
            </div>
          );
        })}
      </div>
      <div className="relative flex max-w-xs md:hidden">
        <div className="group relative shrink-0">
          <button
            type="button"
            className="flex items-center justify-center bg-white p-6 transition"
            onClick={() => setOpen(!open)}
          >
            <span className="absolute text-center transition hover:font-bold group-hover:-translate-y-0.5 group-hover:font-bold group-focus:-translate-y-0.5 group-focus:font-bold">
              <Text variant="sm">Categories</Text>
            </span>
            <span className="invisible block opacity-0" aria-hidden="true">
              Categories
            </span>
          </button>
          <div className="absolute bottom-0 h-0 w-full bg-yellow transition-[height] group-hover:h-[12px] group-active:h-[12px]" />
        </div>
        <m.ul
          className="absolute top-16 z-20 mt-2 flex max-w-xl flex-col divide-y-2 divide-black overflow-hidden bg-white"
          initial="closed"
          variants={variants}
          animate={open ? "show" : "hide"}
          transition={{ duration: 0.3 }}
        >
          {categories?.map(cat => (
            <li key={cat.uid || cat.title}>
              <a
                href="#fast-facts"
                className="block px-2 py-3"
                onClick={() => setOpen(false)}
              >
                <Text variant="xs">{cat?.title}</Text>
              </a>
            </li>
          ))}
        </m.ul>
      </div>
    </>
  );
};

export default VideoFilter;
