import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock-upgrade";

const toggleBodyScroll = (enable, element, options) => {
  if (enable) {
    enableBodyScroll(element, options);
  } else {
    disableBodyScroll(element, options);
  }
};

export default toggleBodyScroll;
