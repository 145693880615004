import React from "react";
import clsx from "clsx";
import { useAppState } from "@state";
import { Text, Image, Button, Icon, LinkAlt } from "@atoms";

const ModalContent = ({
  image,
  heading,
  title,
  content,
  button,
  secondaryLink,
}) => {
  const [{ modal: modalState }, dispatch] = useAppState();
  const { modal: open } = modalState;
  return (
    <div
      className={clsx(
        "relative flex w-full max-w-2.5xl flex-col gap-8 bg-white py-10 sm:flex-row sm:py-16 md:pt-24",
        {
          "pointer-events-auto": open,
        }
      )}
    >
      <button
        type="button"
        title="Close modal"
        aria-label="Close modal"
        className="absolute right-0 top-0 z-30 p-4 duration-200 hover:opacity-70"
        onClick={() => {
          dispatch({ type: "closeModal" });
        }}
      >
        <Icon name="close" className="h-4 w-4" />
      </button>

      <div className="absolute inset-0 z-10 h-24 w-full bg-yellow sm:h-full sm:w-36" />
      {image && (
        <div className="relative z-10 ml-4 sm:ml-12">
          <Image
            image={image}
            className="aspect-square w-28 overflow-hidden rounded-full sm:w-48"
            ixParams={{ fit: "facearea", facepad: 10 }}
          />
        </div>
      )}

      <div className="">
        <div
          className={clsx("relative z-10 px-4 sm:px-0", {
            "border-b-4 border-green-dark pb-6": button?.url,
            "mt-28 sm:ml-56 sm:mt-0": !image,
          })}
        >
          <Text
            variant="h6"
            className={clsx("pr-6 md:pr-10 lg:pr-16", {
              "mb-6": !title,
              "mb-1": title,
            })}
          >
            {heading}
          </Text>
          <Text variant="xs" className="mb-6 md:pr-10 lg:pr-16">
            {title}
          </Text>
          <Text
            variant="sm"
            tag="div"
            className="pr-6 md:pr-10 lg:pr-16"
            richText
          >
            {content}
          </Text>
        </div>
        {(button?.url || secondaryLink?.url) && (
          <div className="flex flex-wrap items-center gap-4 pt-8">
            {button?.url && (
              <Button color="transparent" to={button?.url}>
                <Text variant="button">{button?.text}</Text>
              </Button>
            )}
            {secondaryLink?.url && (
              <LinkAlt to={secondaryLink?.url} text={secondaryLink?.text} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalContent;
