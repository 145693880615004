const plugin = require("tailwindcss/plugin");
const typography = require("@tailwindcss/typography");

const colors = {
  black: { DEFAULT: "#232427" },
  offwhite: { DEFAULT: "#F0E9E1" },
  white: {
    DEFAULT: "#FFFFFF",
  },
  red: {
    DEFAULT: "#FF634E",
    dark: "#4f312f",
    light: "#ffe0dc",
  },
  gray: {
    DEFAULT: "#8f9094",
    light: "#e9e9e9",
  },
  yellow: {
    DEFAULT: "#F9CE21",
  },
  salmon: {
    DEFAULT: "#F3665D",
  },
  blue: {
    DEFAULT: "#A9E5FF",
  },
  green: {
    light: "#25B578",
    DEFAULT: "#1F5249",
    dark: "#202D2A",
  },
  pink: {
    DEFAULT: "#E58ED4",
  },
};

module.exports = {
  colors,
  mode: "jit",
  content: ["./src/**/*.{js,svg,css}"],
  theme: {
    fontSize: {
      xxs: ".625rem",
      xs: ".75rem",
      sm: ".875rem",
      base: "1rem",
      lg: "1.125rem",
      xl: "1.25rem",
      "1.5xl": "1.33rem",
      "2xl": "1.5rem",
      "2.5xl": "1.75rem",
      "3xl": "2rem",
      "3.5xl": "2.25rem",
      "4xl": "2.5rem",
      "4.5xl": "2.75rem",
      "5xl": "3rem",
      "6xl": "4rem",
      "7xl": "5rem",
      "8xl": "6rem",
      "9xl": "7rem",
      "10xl": "8rem",
      h1: "5rem",
      h2: "4rem",
      h3: "3rem",
      h4: "2rem",
      h5: "1.75rem",
      inherit: "inherit",
    },
    screens: {
      xs: "350px", // for super small screens
      sm: "700px", // bigger than most phones
      md: "850px",
      lg: "1200px",
      xl: "1600px", // larger than 15" macbook pro
      "2xl": "2000px",
    },
    fontFamily: {
      "pp-right": ["PP Right Grotesk", "sans-serif"],
      sans: ["Montserrat", "sans-serif"],
    },
    extend: {
      colors,
      maxWidth: {
        "3xs": "13.5rem",
        "2xs": "17rem",
        xs: "20rem",
        sm: "24rem",
        md: "28rem",
        lg: "32rem",
        xl: "36rem",
        "2xl": "44rem",
        "2.5xl": "48rem",
        "3xl": "52rem",
        "4xl": "60rem",
        "5xl": "68rem",
        "6xl": "76rem",
        "7xl": "84rem",
        "8xl": "92rem",
        full: "100%",
      },
      transitionProperty: {
        default:
          " background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, box-shadow",
      },
      opacity: {
        10: ".1",
        15: ".15",
        20: ".2",
        30: ".3",
        40: ".4",
        60: ".6",
        70: ".7",
        80: ".8",
        90: ".9",
      },
      letterSpacing: {
        // calculated to px value of # @ 16px
        "-3": "-0.1875rem",
        "-2": "-0.125rem",
        "-1": "-0.0625rem",
        0: "0px",
        0.5: "0.03125rem",
        1: "0.0625rem",
        2: "0.125rem",
        3: "0.1875rem",
      },
      minHeight: {
        "1/4-screen": "25vh",
        "1/3-screen": "33.333vh",
        "2/5-screen": "40vh",
        "3/7-screen": "43vh",
        "1/2-screen": "50vh",
        "4/7-screen": "57vh",
        "3/5-screen": "60vh",
        "2/3-screen": "66.666vh",
        "3/4-screen": "75vh",
        "7/8-screen": "87.5vh",
      },
      height: {
        25: "25%",
        "1/2-screen": "50vh",
      },
      width: {
        "1/1": "100%",
        "1/7": "14%",
        "2/7": "28%",
        "3/7": "42%",
        "4/7": "58%",
        "5/7": "72%",
        "6/7": "86%",
      },
      borderWidth: {
        3: "3px",
        5: "5px",
        12: "12px",
        20: "20px",
      },
      divideWidth: {
        3: "3px",
        5: "5px",
        12: "12px",
        20: "20px",
      },
      typography: theme => ({
        sm: {
          css: {
            lineHeight: 1.5,
            p: {
              margin: "1.5rem 0",
            },
            // * these match styles defined in the Text component
            h1: {
              fontSize: "2rem",
              marginTop: "1.5rem",
              marginBottom: "1rem",
              lineHeight: 1.125,
            },
            h2: {
              fontSize: "1.5rem",
              marginTop: "1.5rem",
              marginBottom: "1rem",
              lineHeight: 1.125,
            },
            h3: {
              fontSize: "1.33rem",
              marginTop: "1.5rem",
              marginBottom: "1rem",
              lineHeight: 1.125,
            },
            h4: {
              fontSize: "1.125rem",
              marginTop: "1.5rem",
              marginBottom: "1rem",
              lineHeight: 1.125,
            },
          },
        },
        blog: {
          css: {
            "--tw-prose-bullets": theme("colors.yellow.DEFAULT"),
            "--tw-prose-headings": theme("colors.green.DEFAULT"),
            // elements
            h2: {
              fontSize: "1.5rem",
              marginTop: "1.5rem",
              marginBottom: "1rem",
              lineHeight: 1.125,
            },
            h3: {
              fontSize: "1.33rem",
              marginTop: "1.5rem",
              marginBottom: "1rem",
              lineHeight: 1.125,
            },
          },
        },
        copy: {
          css: {
            "--tw-prose-bullets": theme("colors.salmon.DEFAULT"),
          },
        },
        cols: {
          css: {
            h6: {
              marginBottom: "0.4rem",
            },
            "h6:not(:first-child)": {
              marginTop: "1.25rem",
            },
            p: {
              margin: "0 0 1.25rem",
            },
          },
        },
        DEFAULT: {
          css: {
            // variables
            "--tw-prose-body": theme("colors.black.DEFAULT"),
            "--tw-prose-headings": "inherit",
            "--tw-prose-lead": theme("colors.black.DEFAULT"),
            "--tw-prose-links": "inherit",
            "--tw-prose-bold": "inherit",
            "--tw-prose-counters": theme("colors.black.DEFAULT"),
            "--tw-prose-bullets": theme("colors.black.DEFAULT"),
            "--tw-prose-hr": theme("colors.gray.DEFAULT"),
            "--tw-prose-quotes": theme("colors.black.DEFAULT"),
            "--tw-prose-quote-borders": theme("colors.gray.DEFAULT"),
            "--tw-prose-captions": theme("colors.gray.DEFAULT"),
            "--tw-prose-code": theme("colors.black.DEFAULT"),
            "--tw-prose-pre-code": theme("colors.gray.DEFAULT"),
            "--tw-prose-pre-bg": theme("colors.black.DEFAULT"),
            "--tw-prose-th-borders": "transparent",
            "--tw-prose-td-borders": "transparent",
            // element styles
            "ol > li": {
              listStyleType: "decimal-leading-zero",
            },
            "ol > li::marker": {
              fontWeight: "500",
              fontFamily: "Montserrat, sans-serif",
              fontSize: "0.75rem",
            },
            a: {
              transition: "opacity",
              transitionDuration: "100ms",
            },
            "a:hover": {
              opacity: 0.6,
            },
            lineHeight: 1.5,
            p: {
              margin: "1.25rem 0",
            },
            h1: {
              fontSize: "2.5rem",
              marginTop: "1.5rem",
              marginBottom: "1rem",
              lineHeight: 1.125,
            },
            h2: {
              fontSize: "2rem",
              marginTop: "1.5rem",
              marginBottom: "1rem",
              lineHeight: 1.125,
            },
            h3: {
              fontSize: "1.5rem",
              marginTop: "1.5rem",
              marginBottom: "1rem",
              lineHeight: 1.125,
            },
            h4: {
              fontSize: "2.25rem",
              marginTop: "1.5rem",
              marginBottom: "1rem",
              lineHeight: 1.125,
              fontFamily: "PP Right Grotesk, sans-serif",
              fontWeight: "bold",
            },
            h6: {
              fontSize: "1.33rem",
              fontFamily: "PP Right Grotesk, sans-serif",
              fontWeight: "bold",
            },
          },
        },
      }),
      fill: theme => theme("colors"),
      stroke: theme => theme("colors"),
    },
  },
  plugins: [
    typography,
    plugin(({ addUtilities, e, theme, variants }) => {
      Object.entries(theme("gap")).forEach(([key, value]) =>
        addUtilities(
          {
            [`.flex-gap-${e(key)}`]: {
              marginTop: `-${value}`,
              marginLeft: `-${value}`,
              "& > *": {
                paddingTop: value,
                paddingLeft: value,
              },
            },
          },
          variants("gap")
        )
      );
    }),
  ],
};
