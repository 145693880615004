import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query AllPodcastsQuery {
    podcastInfo {
      link
      feedUrl
      description
      copyright
      image {
        link
        title
        url
      }
      title
    }
    allPodcastEpisodes(sort: { isoDate: DESC }) {
      totalCount
      podcasts: nodes {
        guid
        title
        pubDate
        isoDate
        episode
        content
        contentEncoded
        contentSnippet
        image
        audio {
          length
          type
          url
        }
        author
        url
        uri
      }
    }
    _craft: craft {
      globalSet(handle: "podcastLinks") {
        ... on Craft_podcastLinks_GlobalSet {
          podcastAppleLink: link0 {
            text
            url
          }
          podcastSpotifyLink: link1 {
            text
            url
          }
        }
      }
    }
  }
`;

const dataResolver = ({ podcastInfo, allPodcastEpisodes, _craft: craft }) => {
  const { podcasts } = allPodcastEpisodes;
  const { podcastAppleLink, podcastSpotifyLink } = craft.globalSet;
  return {
    podcastInfo,
    podcasts,
    podcastAppleLink,
    podcastSpotifyLink,
  };
};

const useData = () => {
  const data = useStaticQuery(query);
  return dataResolver(data);
};

export default useData;
