import React from "react";
import { Text, LinkAlt } from "@atoms";
import { AppLink } from "@base";

const PodcastCard = ({ title, isoDate, author, url }) => {
  const postDate = new Date(Date.parse(isoDate)).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  return (
    <li className="py-10 text-white sm:py-16">
      <AppLink to={url} className="group text-left">
        <div>
          <Text variant="h6">{title}</Text>

          <div className="mb-8 mt-2 flex flex-wrap gap-2">
            <div>
              <Text variant="sm" className="inline">
                by <span>{author}</span>
              </Text>
            </div>
            <div>
              <Text variant="sm" className="inline">
                on <span>{postDate}</span>
              </Text>
            </div>
          </div>

          <LinkAlt text="Continue Reading" />
        </div>
      </AppLink>
    </li>
  );
};

export default PodcastCard;
