import React from "react";
import { Text, Image } from "@atoms";

const CbVideo = ({ images, caption }) => {
  return (
    <figure className="my-10">
      <div className="flex flex-wrap gap-2 sm:flex-nowrap">
        {images?.map(image => (
          <Image key={image} image={image} />
        ))}
      </div>
      <Text variant="caption" className="mt-2">
        {caption}
      </Text>
    </figure>
  );
};

export default CbVideo;
