import React from "react";
import clsx from "clsx";
import { Text, Image, LinkAlt } from "@atoms";
import { AppLink } from "@base";

const ArticleCard = ({
  title,
  image,
  excerpt,
  url,
  author,
  postDate,
  horizontal,
}) => {
  return (
    <AppLink to={url} className="group w-full text-left">
      <article
        className={clsx("flex", {
          "flex flex-col gap-4 sm:flex-row": horizontal,
          "flex-col": !horizontal,
        })}
      >
        {image && (
          <div
            className={clsx({
              "shrink-0 sm:w-[148px]": horizontal,
              "w-full": !horizontal,
            })}
          >
            <Image
              image={image}
              className={clsx({
                "mb-6 aspect-[363/206] w-full": !horizontal,
                "aspect-[363/206] sm:mb-0 sm:aspect-[148/142]": horizontal,
              })}
              aspectRatio={horizontal ? [1, 1] : null}
            />
          </div>
        )}

        <div>
          <Text
            variant={!horizontal ? "h6" : "h7"}
            className={clsx(
              "block font-bold text-green transition-colors group-hover:text-black",
              {
                "mb-4": !horizontal,
                "mb-1 line-clamp-1": horizontal,
              }
            )}
          >
            {title}
          </Text>

          {postDate && author && (
            <div
              className={clsx("flex flex-wrap gap-2", {
                "mb-6": !horizontal,
                "mb-2": horizontal,
              })}
            >
              <div>
                <Text variant="sm" className="inline">
                  <span className="opacity-50">by</span> <span>{author}</span>
                </Text>
              </div>
              <div>
                <Text variant="sm" className="inline">
                  <span className="opacity-50">on</span> <span>{postDate}</span>
                </Text>
              </div>
            </div>
          )}

          {excerpt && (
            <Text
              variant="sm"
              className={clsx({
                "mb-2 line-clamp-2": horizontal,
                "mb-4": !horizontal,
              })}
            >
              {excerpt}
            </Text>
          )}

          {url && <LinkAlt text="Continue Reading" />}
        </div>
      </article>
    </AppLink>
  );
};

export default ArticleCard;
