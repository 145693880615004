import React from "react";
import { AppLink } from "@base";
import { Icon } from "@atoms";

const Socials = ({ socials }) => {
  return (
    <ul>
      <li className="flex items-center gap-2">
        {socials?.map(s => {
          const { platform, accounturl, uid } = s;
          return (
            <AppLink
              to={accounturl}
              key={uid}
              ariaLabel={platform}
              className="flex h-7 w-7 items-center justify-center rounded-full bg-yellow md:h-5 md:w-5"
            >
              <Icon
                name={platform}
                className="duration-400 text-text h-5 w-5 text-green transition md:h-3 md:w-3"
                fitHeight={platform === "facebook"}
              />
            </AppLink>
          );
        })}
      </li>
    </ul>
  );
};

export default Socials;
