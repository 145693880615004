import React, { useEffect } from "react";
import { PageSizeObserver } from "@base";
import loadable from "@loadable/component";
import { LazyMotion } from "framer-motion";
import { Modal } from "@molecules";

import BrowserWarning from "../atoms/BrowserWarning";

const Header = loadable(() => import("@organisms/Header"));
const GdprBanner = loadable(() => import("@organisms/GdprBanner"));
const Userback = process.env.GATSBY_USERBACK_TOKEN
  ? loadable(() => import("@organisms/Userback"))
  : null;
const Announcement = loadable(() => import("@organisms/Announcement"));

const createPromise = () => {
  let args;
  // eslint-disable-next-line no-return-assign, no-promise-executor-return
  const promise = new Promise((..._) => (args = _));
  return [promise, ...args];
};

const [promise, resolve] = createPromise();
const loadFeatures = () => promise;

const DefaultLayout = ({ children }) => {
  // load animation features
  useEffect(() => {
    resolve(import("@utils/animationFeatures").then(r => r.domMax));
  }, []);

  // the layout
  return (
    <LazyMotion features={loadFeatures}>
      <Announcement />
      <div className="flex min-h-screen flex-col">
        <PageSizeObserver />
        <Header />
        {children}
        <BrowserWarning />
        <GdprBanner />
        {process.env.GATSBY_USERBACK_TOKEN && <Userback />}
        {/* uncomment if embedding facebook posts */}
        {/* <div id="fb-root" /> */}
        <Modal />
      </div>
    </LazyMotion>
  );
};

export default DefaultLayout;
