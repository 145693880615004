import React from "react";
import { Text, Image } from "@atoms";
import { useAppState } from "@state";
import ModalContent from "./ModalContent";

const PersonCard = ({ name, title, headshot, bio, button, link }) => {
  const [, dispatch] = useAppState();

  const triggerModal = () => {
    dispatch({
      type: "openModal",
      title: name,
      description: `${name}'s biography`,
      // eslint-disable-next-line react/no-unstable-nested-components
      content: React.memo(function Modal() {
        return (
          <ModalContent
            heading={name}
            title={title}
            content={bio}
            image={headshot}
            button={button}
            secondaryLink={link}
          />
        );
      }),
      hideButton: true,
    });
  };

  return (
    <button
      type="button"
      onClick={triggerModal}
      className="relative w-full max-w-2xs border-b-20 border-yellow bg-white px-8 pb-8 pt-4"
    >
      <div className="flex h-full flex-col justify-start gap-2">
        <div className="w-full px-4">
          {headshot && (
            <Image
              image={headshot}
              className="mb-8 aspect-square overflow-hidden rounded-full"
              ixParams={{ fit: "facearea", facepad: 10 }}
            />
          )}
        </div>
        <Text variant="h6" className="mx-auto text-center">
          {name}
        </Text>
        <Text variant="sm" className="mx-auto block text-center">
          {title}
        </Text>
      </div>
    </button>
  );
};

export default PersonCard;
