import React from "react";
import { Icon, Text, Image } from "@atoms";

const Quote = ({ attribution, image, title, quote }) => {
  return (
    <div className="flex-col justify-start gap-4 sm:flex sm:flex-row lg:gap-8">
      {image && (
        <div className="relative -top-16 mx-auto h-full max-h-[18rem] w-full max-w-xs sm:top-0 sm:max-h-none md:max-w-md lg:max-w-3xl">
          <div className="relative bottom-0 right-0 w-full pr-10 sm:absolute md:translate-x-10 xl:translate-x-0">
            <Image
              image={image}
              draggable={false}
              className="absolute inset-0 z-10 aspect-square w-full origin-bottom-right overflow-hidden rounded-full bg-green sm:scale-110 xl:scale-125"
            />
            <div className="absolute -bottom-12 right-10 z-0 w-36 sm:right-0 sm:w-1/2">
              <Icon name="starBurst" className="w-full text-yellow" />
            </div>
          </div>
        </div>
      )}
      <figure className="relative z-20 flex w-full max-w-3xl gap-4">
        <Icon name="quote" className="mt-2 w-16 self-start text-blue" />
        <blockquote className="text-white">
          <Text variant="lg" className="mb-6 font-bold italic sm:mb-10">
            {quote}
          </Text>
          <figcaption>
            <Text variant="body" className="mb-1 font-bold">
              {attribution}
            </Text>
            <Text variant="sm">{title}</Text>
          </figcaption>
        </blockquote>
      </figure>
    </div>
  );
};

export default Quote;
