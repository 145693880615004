import React, { useState, useEffect } from "react";
import clsx from "clsx";
import usePagination from "@hooks/usePagination";
import chunkify from "@utils/chunkify";

import { Button, Icon } from "@atoms";

const Pagination = ({
  data,
  pageSize = 9,
  rangeLimit = 1,
  showNavigation = true,
  component: Component,
  className: _className,
  typeState,
  topicState,
}) => {
  const pages = Math.ceil((data?.length || 0) / pageSize);
  const [currentPage, setCurrentPage] = useState(1);

  if (pages) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const paginationRange = usePagination({
      pages,
      currentPage,
      rangeLimit,
    });
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [typeFilter] = typeState || useState(0);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [topicFilter] = topicState || useState(0);

    const nextPage = () => {
      if (currentPage < pages)
        document.getElementById("content").scrollIntoView();
      setCurrentPage(page => (page < pages ? page + 1 : page));
    };
    const prevPage = () => {
      if (currentPage > 1) document.getElementById("content").scrollIntoView();
      setCurrentPage(page => (page > 1 ? page - 1 : 1));
    };
    // use the number within the button to set the page
    const changePage = event => {
      const pageNumber = Number(event.target.textContent);
      document.getElementById("content").scrollIntoView();
      setCurrentPage(pageNumber);
    };
    // chunk the data into pages
    const paginatedData = chunkify([...data], pages, false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      // helps keep you from getting sent a page of already filtered items
      setCurrentPage(1);
    }, [typeFilter, topicFilter]);

    return (
      <>
        <div
          className={clsx(
            "mb-16 flex scroll-my-6 flex-wrap gap-16 sm:mb-20 md:gap-12",
            _className
          )}
          id="content"
        >
          {paginatedData[currentPage - 1]?.map((item, i) => (
            <Component
              key={item?.uid}
              {...item}
              horizontal={
                !(
                  i === 0 &&
                  currentPage === 1 &&
                  typeFilter === 0 &&
                  topicFilter === 0
                )
              }
            />
          ))}
        </div>
        {pages > 1 && (
          <div className="flex flex-wrap gap-2 sm:flex-nowrap">
            {/* previous button */}
            {showNavigation && (
              <Button color="transparent" onClick={prevPage} size="xs">
                <Icon name="chevron" className="h-1.5 w-1.5 rotate-180" />
              </Button>
            )}
            {/* page number buttons */}
            {paginationRange.map((pageNumber, i) => {
              if (pageNumber === "...") {
                return (
                  <span
                    key={`${pageNumber}-${i + 1}`}
                    className="leading-tighter p-2 text-black"
                  >
                    {pageNumber}
                  </span>
                );
              }
              return (
                // using vanilla button here to allow for active button styling
                <button
                  // eslint-disable-next-line react/no-array-index-key
                  key={pageNumber}
                  type="button"
                  className={clsx(
                    "border-4 border-black px-4 py-1.5 text-xs text-black transition-colors duration-300 hover:border-yellow hover:bg-yellow",
                    {
                      "border-yellow bg-yellow": currentPage === pageNumber,
                    }
                  )}
                  onClick={changePage}
                >
                  <span className="font-pp-right font-bold">{pageNumber}</span>
                </button>
              );
            })}
            {/* next button */}
            {showNavigation && (
              <Button color="transparent" onClick={nextPage} size="xs">
                <Icon name="chevron" className="h-1.5 w-1.5" />
              </Button>
            )}
          </div>
        )}
      </>
    );
  }
  return null;
};

export default Pagination;
