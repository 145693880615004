import React from "react";
import { Text } from "@atoms";
import { AppLink } from "@base";
import clsx from "clsx";

const ComponentName = ({ links, className: _className }) => {
  return (
    <ul className={clsx("group space-y-3 hover:text-white/50", _className)}>
      {links.map(link => (
        <li key={link.uid}>
          <AppLink
            to={link.url}
            className="transition-colors duration-300 hover:text-white/100"
          >
            <Text variant="nav">{link.text}</Text>
          </AppLink>
        </li>
      ))}
    </ul>
  );
};

export default ComponentName;
