/**
 *
 * This page container contains the main tag and transition logic. It should
 * wrap all pages in their respective template files. If used in DefaultLayout,
 * it cannot access Transition State.
 *
 */

import React from "react";
import clsx from "clsx";
import { m, AnimatePresence } from "framer-motion";
import { useAppState } from "@state";
import loadable from "@loadable/component";

const Footer = loadable(() => import("@organisms/Footer"));

const PageContainer = ({ children, className, path, flex }) => {
  // eslint-disable-next-line no-empty-pattern
  const [{}, dispatch] = useAppState();

  const variants = {
    enter: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    <m.div
      key={path}
      initial="exit"
      animate="enter"
      exit="exit"
      transition={{
        type: "tween",
        ease: "easeInOut",
        duration: 0.333,
      }}
      variants={variants}
      onAnimationComplete={definition => {
        if (definition === "enter") {
          if (typeof window !== "undefined") {
            const dataLayer = window.dataLayer || [];
            dataLayer.push({ event: "routeChange" });
          }
          dispatch({
            type: "endTransition",
          });
        }
      }}
      className={clsx(className, "flex min-h-screen flex-grow flex-col")}
    >
      <AnimatePresence initial>
        <article
          className={clsx("flex-grow", {
            "flex flex-col items-center justify-center": flex,
          })}
        >
          {children}
        </article>
      </AnimatePresence>
      <Footer />
    </m.div>
  );
};

export default PageContainer;
