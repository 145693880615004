import React, { useState } from "react";
import clsx from "clsx";
import { Button, Text } from "@atoms";

const FormContainer = ({
  children,
  onSubmit, // ! onSubmit function must return object
  buttonText = "Submit",
  className: _className,
  successMessage = "The form has been submitted.",
  errorMessage = "Please fill out all required fields.",
  formState,
  buttonColor,
  buttonAlign = "center",
}) => {
  // Form States
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [form, setForm] = formState || useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [formError, setFormError] = useState([]);

  const requiredFields = React.Children.toArray(children)
    ?.map(_f => _f.props)
    ?.map(_f => {
      if (Array.isArray(_f?.children)) {
        return _f?.children?.map(_c => _c?.props);
      }

      return _f;
    })
    ?.flat()
    ?.filter(_f => _f?.required)
    ?.map(_f => _f?.name)
    ?.sort();

  const childrenWithProps = React.Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        formState: [form, setForm],
        errorState: [formError, setFormError],
      });
    }
    return child;
  });

  const handleSubmit = async e => {
    e.preventDefault();

    const formKeys = Object.keys(form)
      ?.filter(_f => {
        const isEmpty =
          form[_f]?.length === 0 || form[_f] === false || form[_f] === null;
        return !isEmpty ? _f : false;
      })
      ?.sort();
    const hasRequiredFields =
      formKeys?.length > 0 &&
      requiredFields.every(value => {
        return formKeys?.includes(value);
      });

    if (hasRequiredFields && !success) {
      setFormError([]);

      if (onSubmit) {
        try {
          const res = await onSubmit(e);

          if (!res.error) {
            setError(null);
            setSuccess(successMessage);
          } else {
            setSuccess(null);
            setError(
              res.error ||
                "Oops, Something happened with the form submission. Try again later."
            );
          }
        } catch (_e) {
          setError(errorMessage);
        }
      }
    } else {
      const missingFields = requiredFields?.filter(x => !formKeys.includes(x));
      // ?.concat(formKeys.filter(x => !requiredFields.includes(x)));

      setFormError(missingFields);
      setError(errorMessage);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={clsx(_className)}>
      {error && (
        <Text variant="body" className="text-red">
          {error}
        </Text>
      )}
      {success && (
        <Text variant="body" className="">
          {success}
        </Text>
      )}
      {!success && (
        <>
          {childrenWithProps}
          <div
            className={clsx("flex pt-4", {
              "justify-center": buttonAlign === "center",
              "justify-start": buttonAlign === "left",
              "justify-end": buttonAlign === "right",
            })}
          >
            <Button type="submit" color={buttonColor} size="md">
              <Text variant="button">{buttonText}</Text>
            </Button>
          </div>
        </>
      )}
    </form>
  );
};

export default FormContainer;
