import React, { useRef, useEffect } from "react";
import clsx from "clsx";
import { m } from "framer-motion";
import { useAppState } from "@state";
import { Icon } from "@atoms";
import toggleBodyScroll from "@utils/toggleBodyScroll";

const Modal = () => {
  const modal = useRef();
  const [{ modal: modalState }, dispatch] = useAppState();
  const {
    modal: open,
    content: Content,
    background,
    title,
    description,
    focusId,
    hideButton,
  } = modalState;

  const transition = {
    type: "tween",
    duration: 0.2,
  };

  const containerVariants = {
    open: {
      pointerEvents: "auto",
      opacity: 1,
      transition,
    },
    closed: {
      pointerEvents: "none",
      opacity: 0,
      transition,
    },
  };

  useEffect(() => {
    const handleKey = e => {
      if (parseInt(e.keyCode, 10) === 27) {
        dispatch({ type: "closeModal" });
      }
    };
    document.addEventListener("keydown", handleKey);
    return () => {
      document.removeEventListener("keydown", handleKey);
    };
  }, []);

  useEffect(() => {
    if (!open) {
      toggleBodyScroll(true, modal.current);
      dispatch({ type: "closeModal" });
    }
  }, [open]);

  return (
    <m.div
      initial="closed"
      animate={open ? "open" : "closed"}
      variants={containerVariants}
      className={clsx(
        "fixed inset-0 z-[100] overflow-auto bg-opacity-[0.975] md:px-[64px] md:py-[64px]",
        background,
        {
          "pointer-events-none": !open,
        }
      )}
      onAnimationComplete={() => {
        if (!open && Content) {
          dispatch({ type: "clearModalContent" });
        } else if (open) {
          if (focusId) {
            document.getElementById(focusId).focus();
          }

          toggleBodyScroll(false, modal.current);
        }
      }}
    >
      <button
        type="button"
        className="absolute inset-0"
        onClick={() => {
          dispatch({ type: "closeModal" });
        }}
        aria-label="Close modal"
      >
        <span className="sr-only">Close</span>
      </button>

      {!hideButton && (
        <button
          type="button"
          className={clsx(
            "absolute right-0 top-0 z-30 flex items-center p-3 text-xs font-bold uppercase text-white"
          )}
          onClick={() => {
            dispatch({ type: "closeModal" });
          }}
          aria-label="Close modal"
        >
          <span className="sr-only">close</span>
          <Icon name="close" className="h-6 w-6" />
        </button>
      )}

      <div className="flex place-content-center">
        <div
          ref={modal}
          aria-labelledby={title ? "modalTitle" : null}
          aria-describedby={description ? "modalDescription" : null}
        >
          {!!Content && (
            <>
              {title && (
                <h2 id="modalTitle" className="sr-only">
                  {title}
                </h2>
              )}
              {description && (
                <p id="modalDescription" className="sr-only">
                  {description}
                </p>
              )}
              <Content />
            </>
          )}
        </div>
      </div>
    </m.div>
  );
};

export default Modal;
