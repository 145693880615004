import React from "react";
import { Icon } from "@atoms";
import useNewsletterData from "@staticQueries/NewsletterQuery";
import Form from "./Form";

const NewsletterForm = ({ hideStartburst }) => {
  const { formCode } = useNewsletterData();
  return (
    <div className="relative z-[60]">
      {!hideStartburst && (
        <Icon
          name="starBurst"
          className="pointer-events-none invisible absolute -top-[25.5rem] left-0 z-0 h-44 w-44 text-yellow sm:visible sm:-left-24 sm:-top-32 sm:h-[322px] sm:w-[322px] lg:-top-36"
        />
      )}

      <div className="relative z-10 max-w-sm lg:-top-4">
        <Form id={formCode} dependencies={[]} isGiving={false} dark small />
      </div>
    </div>
  );
};

export default NewsletterForm;
