import React, { useState } from "react";
import clsx from "clsx";
import { m } from "framer-motion";

const InViewWrapper = ({
  children,
  unobserveAfterEntry,
  className,
  inViewClassName,
  style,
  inViewStyle,
  onEnter,
  onExit,
  threshold = "some",
  rootMargin = "0px",
}) => {
  const [visible, setVisible] = useState();

  const handleEntry = () => {
    if (onEnter) {
      onEnter();
    }
    setVisible(true);
  };

  const handleExit = () => {
    if (onExit) {
      onExit();
    }
    setVisible(false);
  };

  return (
    <m.div
      className={clsx(className, {
        [inViewClassName]: visible,
      })}
      style={visible ? inViewStyle : style}
      viewport={{
        once: unobserveAfterEntry,
        margin: rootMargin,
        amount: threshold,
      }}
      onViewportEnter={handleEntry}
      onViewportLeave={handleExit}
    >
      {children}
    </m.div>
  );
};

export default InViewWrapper;
