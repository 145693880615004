import React from "react";
import { Text, Icon } from "@atoms";
import clsx from "clsx";
import { m } from "framer-motion";

const FormAccordionItem = ({ icon, heading, copy, i, state: _state }) => {
  const [open, setOpen] = _state;

  return (
    <div
      className={clsx("w-full text-white", {
        "border-t border-white": open === i - 1,
      })}
    >
      <button
        type="button"
        aria-label={`Expand accordion containing information on ${heading}`}
        className="group relative flex w-full items-center justify-between border-b border-white py-5 text-left text-white transition hover:text-black"
        onClick={() => setOpen(last => (last === i ? -1 : i))}
      >
        <div className="absolute -inset-x-2 z-0 my-1 h-full w-[calc(100%+1rem)] border-y-5 border-green transition group-hover:bg-yellow" />
        <div className="relative z-10 flex items-center gap-4">
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: icon }}
            className="svg-icon icon-current w-7"
          />
          <Text variant="h6">{heading}</Text>
        </div>
        <Icon
          name="chevron"
          className={clsx("ml-2 h-1.5 w-1.5 transition", {
            "-rotate-90": open === i,
            "rotate-90": open !== i,
          })}
        />
      </button>
      <m.div
        className="overflow-hidden"
        initial={{ height: 0 }}
        animate={{ height: open === i ? "auto" : 0 }}
        transition={{
          height: { ease: [0.83, 0, 0.17, 1], duration: 0.2 },
        }}
      >
        <div className="px-5 pb-6 pt-2">
          <Text variant="sm" richText className="prose-blog text-current">
            {copy}
          </Text>
        </div>
      </m.div>
    </div>
  );
};

export default FormAccordionItem;
