import React, { useRef, useEffect, useState } from "react";
import { m, useAnimation } from "framer-motion";
import clsx from "clsx";
import useWindowSize from "@hooks/useWindowSize";
import { Icon } from "@atoms";
import tailwindConfig from "@theme";

const Carousel = ({
  children,
  indicators,
  maxVisible = 1,
  className: _className,
  darkIndicators,
  indicatorsAlign = "center",
  buttons,
  hideOverflow,
  innerArrows,
  floatingIndicators,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slideWidth, setSlideWidth] = useState(0);
  const [visibleSlides, setVisibleSlides] = useState(maxVisible);
  const slides = React.Children.map(children, (child, i) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        index: i,
      });
    }
    return child;
  });

  const slideCount = slides.length;
  const carouselControls = useAnimation();
  const { innerWidth: windowWidth } = useWindowSize();
  const carouselContainer = useRef();

  const { screens } = tailwindConfig.theme;

  // TODO: pass currentSlide to child component to enable styling when the currentSlide is active

  const handleDrag = (event, info) => {
    const { x, y } = info.offset;
    const { x: velocity } = info.velocity;
    if (Math.abs(x) > Math.abs(y)) {
      requestAnimationFrame(() => {
        if (x < -slideWidth / slideCount || velocity < -400) {
          setCurrentSlide(prevState => {
            if (prevState < slides.length - visibleSlides) {
              return prevState + 1;
            }
            carouselControls.start({
              x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
            });
            return prevState;
          });
        } else if (x > slideWidth / slideCount || velocity > 400) {
          setCurrentSlide(prevState => {
            if (prevState > 0) {
              return prevState - 1;
            }
            carouselControls.start({
              x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
            });
            return prevState;
          });
        } else {
          carouselControls.start({
            x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
          });
        }
      });
    }
  };

  // calculate # of slides that are visible
  const calculateVisibleSlides = width => {
    if (maxVisible > 1) {
      const screenNumbers = {};
      Object.keys(screens).map(screen => {
        if (typeof screens[screen] === "string") {
          screenNumbers[screen] = parseInt(
            screens[screen].replace("px", ""),
            10
          );
        }
        return true;
      });
      // configure number of slides based on screen size
      const noSlides = {
        sm: 1,
        md: 1,
        lg: 3,
        xl: maxVisible,
      };
      // match screen
      const matchedScreen = Object.keys(screenNumbers).find(screen => {
        return width < screenNumbers[screen];
      });
      // return match
      if (matchedScreen && noSlides[matchedScreen] <= maxVisible) {
        return noSlides[matchedScreen];
      }
    }
    return maxVisible;
  };

  useEffect(() => {
    carouselControls.start({
      x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
    });
  }, [currentSlide]);

  // change slide width on window resize
  useEffect(() => {
    if (carouselContainer.current) {
      requestAnimationFrame(() => {
        setSlideWidth(carouselContainer.current.clientWidth);
      });
    }
  }, [carouselContainer, windowWidth, visibleSlides]);

  // calculate visible slides on window resize
  useEffect(() => {
    if (carouselContainer.current) {
      requestAnimationFrame(() => {
        const newSlides = calculateVisibleSlides(windowWidth);
        setVisibleSlides(newSlides);
      });
    }
  }, [windowWidth]);

  return (
    <>
      <div
        ref={carouselContainer}
        className={clsx("relative w-full", _className)}
      >
        {/* prev button */}
        {slideCount > 1 && (
          <div
            className={clsx(
              "translate-y-50 absolute bottom-0 top-0 z-10 hidden transform items-center justify-center md:flex",
              { "-left-12": !innerArrows, "left-12": innerArrows }
            )}
          >
            <button
              className={clsx(
                "group flex items-center justify-center overflow-hidden rounded-full bg-yellow p-3 text-white transition duration-100",
                {
                  "opacity-20": currentSlide <= 0,
                }
              )}
              type="button"
              onClick={() => {
                setCurrentSlide(prevState => {
                  if (prevState > 0) {
                    return prevState - 1;
                  }
                  return prevState;
                });
              }}
              aria-label="Go to the previous slide"
            >
              <Icon
                name="chevron"
                className="relative right-0 h-2 w-2 rotate-180 transform text-green transition duration-100 group-hover:-translate-x-1.5"
              />
            </button>
          </div>
        )}
        <div className={clsx({ "overflow-x-clip": hideOverflow })}>
          <m.div
            animate={carouselControls}
            className="flex"
            transition={{ duration: 0.5, type: "tween" }}
            style={{ width: `${slideCount * 100}%` }}
            drag={slideCount > 1 ? "x" : false}
            onDragEnd={handleDrag}
            dragConstraints={{ left: "-100%", right: 0 }}
            dragDirectionLock
          >
            {slides.map((slide, i) => (
              <div
                key={slide.uid}
                className={clsx("relative flex justify-center duration-200", {
                  "scale-90":
                    (i < currentSlide ||
                      i + 1 > currentSlide + visibleSlides) &&
                    !hideOverflow,
                })}
                style={{ width: `${(1 / visibleSlides / slideCount) * 100}%` }}
              >
                {slide}
              </div>
            ))}
          </m.div>
        </div>
        {/* next button */}
        {slideCount > 1 && (
          <div
            className={clsx(
              "translate-y-50 absolute bottom-0 top-0 z-10 hidden transform items-center justify-center md:flex",
              { "-right-12": !innerArrows, "right-12": innerArrows }
            )}
          >
            <button
              className={clsx(
                "group flex items-center justify-center overflow-hidden rounded-full bg-yellow p-3 text-white transition duration-100",
                {
                  "opacity-20": currentSlide >= slideCount - visibleSlides,
                }
              )}
              type="button"
              onClick={() => {
                setCurrentSlide(prevState => {
                  if (prevState < slideCount - visibleSlides) {
                    return prevState + 1;
                  }
                  return prevState;
                });
              }}
              aria-label="Go to the next slide"
            >
              <Icon
                name="chevron"
                className="relative right-0 h-2 w-2 text-green transition duration-100 group-hover:translate-x-1.5"
              />
            </button>
          </div>
        )}
      </div>
      {/* indicators */}
      {buttons && indicators && slideCount > 1 && (
        <div className="ml-auto mr-auto mt-16 flex  max-w-md flex-col items-center justify-between gap-6 sm:mr-0 sm:flex-row lg:max-w-lg lg:pl-4 xl:max-w-xl">
          {buttons}
          <ul
            className={clsx("flex items-center", {
              "justify-center": indicatorsAlign === "center",
              "justify-end": indicatorsAlign === "right",
            })}
          >
            {slides.map((slide, i) => (
              <li key={slide.uid}>
                <button
                  type="button"
                  onClick={() =>
                    setCurrentSlide(
                      i + 1 > slideCount - visibleSlides
                        ? slideCount - visibleSlides
                        : i
                    )
                  }
                  className={clsx(
                    "mx-1 block h-3.5 w-3.5 rounded-full border-4 transition duration-100",
                    {
                      "border-white bg-transparent":
                        currentSlide !== i && !darkIndicators,
                      "border-green-dark bg-transparent":
                        currentSlide !== i && darkIndicators,
                      "border-yellow bg-yellow": currentSlide === i,
                    }
                  )}
                  aria-label={`Go to slide ${i + 1}`}
                >
                  <span className="hidden">{i}</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      {!buttons && indicators && slideCount > 1 && (
        <ul
          className={clsx("mt-10 flex items-center", {
            "justify-center": indicatorsAlign === "center",
            "justify-end": indicatorsAlign === "right",
            "justify-center md:justify-end": indicatorsAlign === "md:right",
            "bottom-10 right-12 mt-10 md:absolute md:mt-0": floatingIndicators,
          })}
        >
          {slides.map((slide, i) => (
            <li key={slide.uid}>
              <button
                type="button"
                onClick={() =>
                  setCurrentSlide(
                    i + 1 > slideCount - visibleSlides
                      ? slideCount - visibleSlides
                      : i
                  )
                }
                className={clsx(
                  "mx-1 block h-3.5 w-3.5 rounded-full border-4 transition duration-100",
                  {
                    "border-white bg-transparent":
                      currentSlide !== i && !darkIndicators,
                    "border-green-dark bg-transparent":
                      currentSlide !== i && darkIndicators,
                    "border-yellow bg-yellow": currentSlide === i,
                  }
                )}
                aria-label={`Go to slide ${i + 1}`}
              >
                <span className="hidden">{i}</span>
              </button>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default Carousel;
