import React, { useEffect, useState } from "react";
import loadable from "@loadable/component";
import { Text } from "@atoms";

const Video = loadable(() => import("../atoms/Video"));

const CbVideo = ({ videoUrl, caption }) => {
  const [load, setLoad] = useState(false);

  useEffect(() => {
    setLoad(true);
  }, []);

  return (
    <figure className="my-10">
      {load && videoUrl && (
        <Video url={videoUrl} loop={false} autoplay={false} showControls />
      )}
      <Text variant="caption" className="mt-2">
        {caption}
      </Text>
    </figure>
  );
};

export default CbVideo;
