import React from "react";
import clsx from "clsx";

const Text = ({
  children,
  className: _className,
  variant,
  richText,
  quotes,
  tag = "span",
}) => {
  let Tag = tag;
  let classes = "text-base";

  switch (variant) {
    case "h1":
      Tag = "h1";
      classes =
        "font-pp-right text-3xl font-black md:text-4xl lg:text-5xl leading-tighter";
      break;
    case "h2":
      Tag = "h2";
      classes =
        "font-pp-right font-black text-4xl md:text-5xl lg:text-6xl leading-tighter";
      break;
    case "h3":
      Tag = "h3";
      classes =
        "font-pp-right font-black text-2.5xl md:text-3.5xl lg:text-4xl leading-tighter";
      break;
    case "h4":
      Tag = "h4";
      classes =
        "font-pp-right font-black text-2.5xl md:text-3xl lg:text-3.5xl leading-tighter";
      break;
    case "h5":
      Tag = "h5";
      classes =
        "font-pp-right font-black text-2xl md:text-2.5xl lg:text-3xl leading-tighter";
      break;
    case "h6":
      Tag = "h6";
      classes =
        "font-pp-right font-black text-lg md:text-1.5xl leading-tighter";
      break;
    case "h7":
      Tag = "strong";
      classes = "font-black text-body lg:text-lg block leading-tighter";
      break;
    case "xl":
      classes = "block text-xl leading-normal font-sans";
      break;
    case "lg":
      classes = "block text-lg leading-normal font-sans";
      break;
    case "body":
      classes = "block text-base leading-normal font-sans";
      break;
    case "sm":
      classes = "block text-sm leading-normal font-sans";
      break;
    case "xs":
      classes = "block text-xs font-sans leading-tight";
      break;
    case "xxs":
      classes = "block text-xxs font-sans leading-tight";
      break;
    case "label":
      classes =
        "block text-sm font-bold leading-none font-sans uppercase tracking-0.5";
      break;
    case "labelLg":
      classes =
        "block text-body font-bold leading-none font-sans uppercase tracking-0.5";
      break;
    case "nav":
      classes =
        "block text-xs font-pp-right font-black leading-none tracking-0";
      break;
    case "navMobile":
      classes =
        "block text-xs sm:text-base font-pp-right font-black leading-none tracking-0";
      break;
    case "button":
      classes =
        "block text-xs font-pp-right font-black leading-none tracking-1";
      break;
    case "buttonLg":
      classes =
        "block text-sm font-pp-right font-black leading-none tracking-1";
      break;
    case "caption":
      Tag = "figcaption";
      classes = "block text-xs font-sans leading-tight italic";
      break;
    default:
      classes = "";
      break;
  }

  // Now using Tailwind's Typography plugin but since
  // Craft Redactor is usually returning string, it'll render those out.
  // Styling can still be handled with prose and no need
  // for the `richText` utility classes
  // docs: https://tailwindcss.com/docs/typography-plugin
  return React.Children.toArray(children).map((child, i) => {
    if (typeof child.type === "undefined") {
      return (
        <Tag
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          className={clsx(classes, _className, {
            quotes,
            prose: richText,
          })}
          dangerouslySetInnerHTML={{ __html: child }}
        />
      );
    }
    // otherwise return children
    return (
      <Tag
        // eslint-disable-next-line react/no-array-index-key
        key={i}
        className={clsx(classes, _className, {
          quotes,
          prose: richText,
        })}
      >
        {child}
      </Tag>
    );
  });
};

export default Text;
